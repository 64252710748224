body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* -------- */
/* Homepage */
/* -------- */
.serviceCategoryDescriptionOdd {
  background: linear-gradient(to right, #161F6D, #0A6BAC);
}

.serviceCategoryDescriptionEven {
  background: linear-gradient(to right, #0A6BAC, #00ABE1);
}

.linkAvatar {
  background-color: rgba(244, 244, 244, 0.3) !important;
  border: 2px solid white !important;
  color: white;
  width: 70px !important;
  height: 70px !important;
  position: absolute !important;
  top: calc(50% - 35px) !important;
  left: calc(50% - 35px) !important;
  transform: rotateY(0deg) rotate(45deg) !important;
  z-index: 3 !important;
}

.linkAvatar:hover {
  background-color: rgba(244, 244, 244, 0.7) !important;
  border: 3px solid white !important;
  cursor: pointer;
  width: 80px !important;
  height: 80px !important;
  transition: linear 200ms;
  top: calc(50% - 40px) !important;
  left: calc(50% - 40px) !important;
}

.linkAvatar:hover .serviceCategoryLinkIcon {
  font-size: 50px !important;
  transition: linear 200ms;
}

.serviceCategoryLinkIcon {
  font-size: 40px !important;
}

.homepageServiceImage {
  overflow: hidden !important;
}

.homepageServiceImage:hover {
  opacity: 0.9 !important;
  transform: scale(1.05) rotateY(0deg) rotate(1deg) !important;
  overflow: hidden !important;
  transition: linear 200ms;
}


/* ---------- */
/* MenuButton */
/* ---------- */
.iconButtonMenuItemStack:hover {
  background: #f3f3f3 !important;
}

.iconButtonMenuItem {
  width: 100% !important;
}

.iconButtonMenuItem:hover {
  background: none !important;
}